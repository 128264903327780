<template>
  <div class="container">
    <Header :active="8" />
    <div class="banner-box">
      <el-image
        class="banner-box-img"
        src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/productCenter/banner.png"
        lazy
      ></el-image>
      <div class="banner-box-text-box">
        <div class="banner-box-text-box-title">
          <div>产品中心</div>
        </div>
        <div class="banner-box-text-box-tips">Product Center</div>
        <div
          class="horizontal-line"
          style="margin-top: 21px; background: white"
        ></div>
      </div>
    </div>
    <div class="section1">
      <div v-for="(item, ind) in productList" :key="ind">
        <div class="section1-box" v-if="item.imgPosition == 'right'">
          <div class="section1-box-text">
            <p class="section1-box-text-title">{{ item.title }}</p>
            <p class="section1-box-text-subTitle">{{ item.subTitle }}</p>
            <div class="horizontal-line"></div>
            <div
              class="section1-box-text-items"
              v-for="(el, i) in item.items"
              :key="i"
            >
              <p class="title">
                {{ el.title }}
                <span class="subTitleAfter" v-if="el.subTitleAfter">{{
                  el.subTitleAfter
                }}</span>
              </p>
              <p class="subTitle" v-if="el.subTitle">{{ el.subTitle }}</p>
              <p class="subTitleGreen" v-if="el.subTitleGreen">
                {{ el.subTitleGreen }}
              </p>
            </div>
            <img
              src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/productCenter/icon_buy.png"
              alt=""
              class="section1-box-text-buy"
              @click="toAboutUs"
            />
          </div>
          <el-image
            :class="item.class + ' section1-box-img'"
            :src="item.img"
            lazy
          ></el-image>
        </div>
        <div class="section1-box" v-else>
          <el-image
            :class="item.class + ' section1-box-img'"
            :src="item.img"
            lazy
          ></el-image>
          <div class="section1-box-text">
            <p class="section1-box-text-title">{{ item.title }}</p>
            <p class="section1-box-text-subTitle">{{ item.subTitle }}</p>
            <div class="horizontal-line"></div>
            <div
              class="section1-box-text-items"
              v-for="(el, i) in item.items"
              :key="i"
            >
              <p class="title">
                {{ el.title }}
                <span class="subTitleAfter" v-if="el.subTitleAfter">{{
                  el.subTitleAfter
                }}</span>
              </p>
              <p class="subTitle" v-if="el.subTitle">{{ el.subTitle }}</p>
              <p class="subTitleGreen" v-if="el.subTitleGreen">
                {{ el.subTitleGreen }}
              </p>
            </div>
            <img
              src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/productCenter/icon_buy.png"
              alt=""
              class="section1-box-text-buy"
              @click="toAboutUs"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "productCenter",
  data() {
    return {
      productList: [
        {
          title: "1CARE套件（家用版）",
          subTitle: "1CARE suite (Home edition)",
          img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/productCenter/product-img1.png",
          class: "productItem1",
          items: [
            {
              title: "1CARE 手表",
            },
            {
              title: "选配表带",
            },
            {
              title: "充电器",
            },
            {
              title: "五个信标",
              subTitleAfter: "(易于安装在卧室、浴室、厨房、客厅、餐厅)",
            },
            {
              title: "系统安装指南",
            },
            {
              title: "1CARE个人移动端系统",
              subTitleGreen:
                "有偿使用",
            },
          ],
        },
        {
          title: "1CARE套件（专业版）",
          subTitle: "1CARE suite (Professional edition)",
          img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/productCenter/product-img2.png",
          class: "productItem2",
          imgPosition: "right",
          items: [
            {
              title: "1CARE 手表",
            },
            {
              title: "选配表带",
            },
            {
              title: "充电器",
            },
            {
              title: "AOA基站",
              subTitleAfter: "(专业安装)",
            },
            {
              title: "系统安装指南",
            },
            {
              title: "1CARE管理端、移动端、用户端系统",
              // subTitleGreen:
              //   "系统免费使用三年，之后，您仅需支付很小一部分费用便可继续使用",
            },
          ],
        },
        {
          title: "家用医疗监测设备",
          subTitle: "1CARE suite (Home edition)",
          img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/productCenter/product-img3.png",
          class: "productItem3",
          width: "508px",
          height: "499px",
          items: [
            {
              title: "1CARE 体重称",
            },
            {
              title: "欧姆龙血压计（U32J）",
            },
            {
              title: "罗氏血糖仪（逸智）",
            },
            {
              title: "家庭医生咨询",
            },
            {
              title: "系统安装指南",
            },
            {
              title: "* 蓝牙直接对接系统，测量数据自动上传平台，无须手工录入",
            },
          ],
        },
      ],
    };
  },
  methods: {
    toAboutUs() {
      this.$router.push({ path: "/aboutUs", query: { target: "target" } });
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  background: #eef1f5;
}
.horizontal-line {
  width: 73px;
  height: 5px;
  background: #90c446;
}
.title-box {
  .title {
    font-family: "UI-Bold";
    font-size: 52px;
    color: #1f3876;
  }
  .subtitle {
    font-family: "CN-Light";
    font-size: 31px;
    margin-top: 5px;
    color: #90c446;
  }
  .info {
    display: flex;
    margin-top: 29px;
    &-left {
      margin-right: 30px;
      img {
        width: 23px;
        height: 24px;
      }
    }
    &-right {
      flex: 1;
      font-family: "CN-Light";
      font-size: 21px;
      color: #727171;
    }
  }
}
.banner-box {
  position: relative;
  &-img {
    width: 100%;
  }
  &-text-box {
    position: absolute;
    width: 1200px;
    box-sizing: border-box;
    // height: 300px;
    // left: 360px;
    // right: 0;
    top: 134px;
    left: 0;
    right: 0;
    margin: 0 auto;
    color: white;
    &-title {
      font-size: 64px;
      font-family: "UI-Bold";
    }
    &-tips {
      font-family: "CN-Light";
      font-size: 31px;
      margin-top: 17px;
    }
    &-info {
      font-family: "CN-Light";
      line-height: 40px;
      color: #213763;
      width: 427px;
    }
    &-btn {
      width: 120px;
      height: 30px;
      margin-top: 60px;
      cursor: pointer;
    }
    &-btn:hover {
      transform: scale(1.02);
      transition: all 0.5s;
    }
  }
}
.section1 {
  width: 100%;
  padding-bottom: 360px;
  &-box {
    width: 1200px;
    height: 690px;
    margin: 0 auto;
    padding-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-text {
      width: 606px;
      &-title {
        font-size: 52px;
        color: #1f3876;
        font-family: "UI-Regular";
        font-weight: bold;
      }
      &-subTitle {
        font-family: "CN-Light";
        font-size: 31px;
        margin-top: 5px;
        color: #90c446;
      }
      .horizontal-line {
        margin: 28px 0 50px 0;
      }
      &-items {
        font-size: 26px;
        color: #1f3876;
        margin-bottom: 10px;
      }
      .title {
        position: relative;
        padding-left: 38px;
        cursor: pointer;
        transition: all 0.8s;
      }
      .subTitleAfter,
      .subTitle {
        font-size: 18px;
        color: #939393;
      }
      .subTitle {
        padding-left: 38px;
      }
      .subTitleGreen {
        font-size: 18px;
        color: #90c446;
        padding-left: 38px;
      }
      .title::before {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background: #90c446;
        content: "";
        position: absolute;
        left: 0px;
        top: 10px;
        display: inline-block;
      }
      .title:hover {
        transform: scale(1.02);
      }
      &-buy {
        width: 191px;
        height: 57px;
        margin-top: 47px;
        cursor: pointer;
        border-radius: 50px;
        transition: all 0.8s;
      }
      &-buy:hover {
        transform: translateY(-3px);
        box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
      }
    }
    &-img {
      transition: all 0.8s;
      cursor: pointer;
    }
    &-img:hover {
      transform: scale(1.02);
    }
    .productItem1 {
      width: 530px;
      height: 466px;
    }
    .productItem2 {
      width: 560px;
      height: 462px;
    }
    .productItem3 {
      width: 508px;
      height: 499px;
    }
  }
}
</style>
